import { ApplicationInsights } from "@microsoft/applicationinsights-web";

let appInsights = null;
const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;

if (connectionString) {
    appInsights = new ApplicationInsights({
    config: {
        connectionString: connectionString,
        enableAutoRouteTracking: true, // Optional: Automatically track route changes
        },
    });
    appInsights.loadAppInsights();
    console.log("Application Insights initialized.");
} else {
    console.log(
    "No Application Insights connection string provided. Skipping initialization."
    );
}

export default appInsights;